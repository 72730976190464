import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { version } from "../../../../package.json";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  public verison: string = version;
  constructor(public translate: TranslateService) {}
}
