<div class="modal-header">
  <h4 class="modal-title">
    {{ "subscription-page.cancel.title" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-md-12 fs-4">
        {{ "subscription-page.cancel.reason" | translate }}:
      </div>
      <div class="col-12 form-check" *ngFor="let reason of reasons">
        <input
          class="form-check-input"
          type="radio"
          name="reasons"
          [(ngModel)]="selectedReason"
          [value]="reason"
          id="{{ 'reasons.' + reason }}"
        />
        <label class="form-check-label" for="{{ 'reasons.' + reason }}">
          {{ "sub_cancel.reasons." + reason | translate }}
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="reasons"
          [(ngModel)]="selectedReason"
          [value]="'other'"
          id="{{ 'reasons.' + 'other' }}"
        />
        <label class="form-check-label" for="{{ 'reasons.' + 'other' }}">
          {{ "sub_cancel.reasons." + "other" | translate }}
        </label>
        <textarea
          class="form-control"
          *ngIf="selectedReason == 'other'"
          [(ngModel)]="message"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="cancelSubscription()"
    [disabled]="!selectedReason"
  >
    {{ "subscription-page.cancel.confirm" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-outline-success"
    (click)="activeModal.close()"
  >
    {{ "subscription-page.cancel.decline" | translate }}
  </button>
</div>
