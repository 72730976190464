import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { VpsCardComponent } from "./vps-card/vps-card.component";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { ProductCategory } from "../../../Core/models/Product";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Currency } from "../../../Core/models/Currency";

@Component({
  selector: "app-vps-subcategories-page",
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    TranslateModule,
    RouterLink,
    VpsCardComponent,
  ],
  templateUrl: "./vps-subcategories-page.component.html",
  styleUrl: "./vps-subcategories-page.component.css",
})
export class VpsSubcategoriesPageComponent {
  @Input() vps_category?: ProductCategory;
  @Input() currency?: Currency;
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private jwtService: JwtHelperService
  ) {}
}
