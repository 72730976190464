import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-string-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './string-select.component.html',
  styleUrl: './string-select.component.css',
})
export class StringSelectComponent {
  @Input() values: any[] = [];
  @Input() name: string;
  @Input() cartName: string = 'cartname';
  @Output() onChange: EventEmitter<never> = new EventEmitter<never>();
}
