import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConsoleService {
  consoles: Window[] = [];
  constructor() {}

  addConsole(console: Window) {
    this.consoles.push(console);
  }

  clearConsoles() {
    this.consoles.forEach((console) => {
      console.close();
    });
    this.consoles = [];
  }
}
