import { Component } from "@angular/core";
import { FormControl, FormsModule, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../Core/Services/Api/api.service";
import { ToastService } from "../../Core/Toast/toast.service";
import { CommonModule } from "@angular/common";
import { UserService } from "../../Core/Services/User/user.service";

@Component({
  selector: "app-server-colocation",
  standalone: true,
  imports: [TranslateModule, FormsModule, CommonModule],
  templateUrl: "./server-colocation.component.html",
  styleUrl: "./server-colocation.component.scss",
})
export class ServerColocationComponent {
  name: string = "";
  email: string = "";
  tel: string = "";
  message: string = "";
  emailRegx: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  emailIsTouched = false;
  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private toastService: ToastService,
    public userService: UserService
  ) {}

  isValidEmail(): boolean {
    return this.email.match(this.emailRegx) !== null;
  }

  sendMessage() {
    this.apiService
      .sendMessage(
        this.name,
        this.email,
        "Server colocation",
        `Tel.: ${this.tel}, \n ${this.message}`
      )
      .subscribe({
        next: (response) => {
          if (response.result) {
            this.translate
              .get("basic.message-sent-success")
              .subscribe((message) => {
                this.toastService.showAlert(message);
              });
          } else {
            this.translate
              .get("basic.message-sent-fail")
              .subscribe((message) => {
                this.toastService.showAlert(message);
              });
          }
          this.name = "";
          this.email = "";
          this.tel = "";
          this.message = "";
        },
      });
  }
}
