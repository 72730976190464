import { Param, Product } from './Product';

export interface Cart {
  period?: number;
  items: CartItem[];
}

export class CartItem {
  productid: number;
  product?: Product;
  quantity: number;
  name?: string;
  params?: Param[];
  extras: CartExtra[];
  brutto_price?: number;
  netto_price?: number;
  afa_price?: number;
}

export class CartExtra {
  productid: number;
  quantity: number;
  extra?: any;
  name?: string;
  item?: string;
  type?: string;
  brutto_price?: number;
  netto_price?: number;
  afa_price?: number;
}
