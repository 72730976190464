<div class="container mb-5">
  <div class="card mt-5">
    <div class="card-header">
      <h2 class="mt-2">
        {{ "cart.title" | translate }}
        <button
          class="btn btn-outline-danger float-end"
          (click)="clearCart()"
          [disabled]="cartService.getItems().length == 0"
        >
          {{ "cart.delete" | translate }}
        </button>
      </h2>
    </div>
    <div class="card-body">
      <ng-container *ngIf="prices">
        <app-cart-item
          *ngFor="let item of cartService.getItems(); index as ind"
          [item]="item"
          [price]="prices.items[ind]"
          (delete)="get_prices()"
        ></app-cart-item>
      </ng-container>
      <div class="container" *ngIf="cartService.getItems().length > 0">
        <div class="row">
          <div class="col-lg-5">
            <div class="input-group mt-4">
              <span class="input-group-text">{{
                "cart.cupon" | translate
              }}</span>
              <input
                class="form-control"
                type="text"
                maxlength="10"
                [(ngModel)]="cupon"
                (keyup)="cuponChanged()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <div class="input-group mt-4">
              <span class="input-group-text">{{
                "cart.payment_period" | translate
              }}</span>
              <select
                class="form-control text-center"
                [(ngModel)]="selectedPricetype"
                (change)="get_prices()"
              >
                <ng-container *ngFor="let priceType of pricetypes">
                  <option
                    value="{{ priceType.id }}"
                    *ngIf="avaibalePeriods && isAvailable(priceType.id)"
                  >
                    {{ priceType.name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="col-lg-6 offset-1">
            <table class="table">
              <thead>
                <th></th>
                <th>{{ "cart.summary.netto" | translate }}</th>
                <th>{{ "cart.summary.afa" | translate }}</th>
                <th>{{ "cart.summary.brutto" | translate }}</th>
              </thead>
              <tbody>
                <tr *ngIf="prices?.kuponnetto">
                  <td>{{ "cart.cupon_discount" | translate }}</td>
                  <td>
                    {{ currencyService.printCurrency(prices?.kuponnetto) }}
                  </td>
                  <td>
                    {{ currencyService.printCurrency(prices?.kuponafa) }}
                  </td>
                  <td>
                    {{ currencyService.printCurrency(prices?.kuponbrutto) }}
                  </td>
                </tr>
                <tr>
                  <td>{{ "cart.summary.sum" | translate }}:</td>
                  <td>
                    {{ currencyService.printCurrency(prices?.sumpricenetto) }}
                  </td>
                  <td>
                    {{ currencyService.printCurrency(prices?.sumpriceafa) }}
                  </td>
                  <td>
                    {{ currencyService.printCurrency(prices?.sumpricebrutto) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="cartService.getItems().length == 0">
        <h2 class="text-center">{{ "cart.empty" | translate }}</h2>
      </div>
    </div>
  </div>
  <div class="card mt-3" *ngIf="cartService.getItems().length > 0">
    <div class="card-header">
      <h2 class="mt-2">{{ "cart.order.title" | translate }}</h2>
    </div>
    <div class="card-body">
      <div class="container" *ngIf="userService.isLoggedIn && false">
        <h2 class="text-center m-2 p-2 fs-2 fw-bold mt-5">Billing Address</h2>
        <div class="row mt-3">
          <div class="col-6 mt-3">
            <label for="CName" class="form-label">Billing name</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-6 mt-3">
            <label for="CName" class="form-label">Address 1</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-6 mt-3">
            <label for="CName" class="form-label">Address 2</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-6 mt-3">
            <label for="CName" class="form-label">City</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-3 mt-3">
            <label for="CName" class="form-label">State/Region</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-3 mt-3">
            <label for="CName" class="form-label">Zip Code</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-6 mt-3">
            <label for="CName" class="form-label">Country</label>
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>
      <div
        class="container"
        *ngIf="
          !(userService.isLoggedIn | async) && (!registerNewUser || activated)
        "
      >
        <div
          class="row justify-content-center text-center"
          *ngIf="!registerNewUser"
        >
          <button
            class="btn btn-outline-success col-4 m-2"
            (click)="openUserOffCanvas()"
          >
            {{ "cart.order.login" | translate }}
          </button>
        </div>
        <div
          class="row justify-content-center text-center"
          *ngIf="!registerNewUser"
        >
          <button
            class="btn btn-outline-danger col-4 m-2"
            (click)="open(registerContent)"
          >
            {{ "cart.order.register" | translate }}
          </button>
        </div>
      </div>
      <form
        [formGroup]="code"
        *ngIf="registerNewUser && !(userService.isActivated | async)"
      >
        <div class="container mb-5">
          <h2 class="text-center m-2 p-2 fs-1 fw-bold">
            {{ "register.activation" | translate }}
          </h2>
          <div class="row justify-content-center">
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_1"
                (input)="next_input('c_1', 'c_2')"
                (paste)="onPaste($event)"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_1"
                [ngClass]="
                  code.controls['c_1'].value
                    ? 'border-success'
                    : code.controls['c_1'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_2"
                (input)="next_input('c_2', 'c_3')"
                (paste)="onPaste($event)"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_2"
                [ngClass]="
                  code.controls['c_2'].value
                    ? 'border-success'
                    : code.controls['c_2'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_3"
                (input)="next_input('c_3', 'c_4')"
                (paste)="onPaste($event)"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_3"
                [ngClass]="
                  code.controls['c_3'].value
                    ? 'border-success'
                    : code.controls['c_3'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_4"
                (input)="next_input('c_4', 'c_5')"
                (paste)="onPaste($event)"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_4"
                [ngClass]="
                  code.controls['c_4'].value
                    ? 'border-success'
                    : code.controls['c_4'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_5"
                (input)="next_input('c_5', 'c_6')"
                (paste)="onPaste($event)"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_5"
                [ngClass]="
                  code.controls['c_5'].value
                    ? 'border-success'
                    : code.controls['c_5'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
            <div class="col col-1">
              <input
                maxlength="1"
                id="c_6"
                (paste)="onPaste($event)"
                (input)="next_input('', '')"
                class="col-1 form-control fs-3 fw-bold text-center border"
                formControlName="c_6"
                [ngClass]="
                  code.controls['c_6'].value
                    ? 'border-success'
                    : code.controls['c_6'].touched
                    ? 'border-danger'
                    : 'border-primary'
                "
              />
            </div>
          </div>
        </div>
      </form>

      <div
        class="container mt-5"
        *ngIf="
          (userService.isLoggedIn | async) && (!registerNewUser || activated)
        "
      >
        <div class="row justify-content-center mt-2">
          <div class="form-check col-4">
            <input
              [(ngModel)]="check_1"
              class="form-check-input"
              type="checkbox"
              value=""
              id="check_1"
            />
            <label class="form-check-label" for="check_1">
              {{ "cart.order.check_1" | translate }}
            </label>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="form-check col-4">
            <input
              [(ngModel)]="check_2"
              class="form-check-input"
              type="checkbox"
              value=""
              id="check_2"
            />
            <label class="form-check-label" for="check_2">
              {{ "cart.order.check_before" | translate }}
              <a
                [href]="'footer.docs.privacy_policy' | translate"
                target="_blank"
                >{{ "cart.order.check_2" | translate }}
              </a>
            </label>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="form-check col-4">
            <input
              [(ngModel)]="check_3"
              class="form-check-input"
              type="checkbox"
              value=""
              id="check_3"
            />
            <label class="form-check-label" for="check_3">
              {{ "cart.order.check_before" | translate }}
              <a [href]="'footer.docs.gtc' | translate" target="_blank"
                >{{ "cart.order.check_3" | translate }}
              </a>
            </label>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-md-3 col-sm-12 col-xs-12 mt-2">
            <ng-container *ngIf="BillingAddresses.length > 0">
              <select
                class="form-control form-select col-md-4 col-sm-12 col-xs-12 mt-2"
                [(ngModel)]="selectedBillingAddressId"
              >
                <option
                  value="{{ address.id }}"
                  *ngFor="let address of BillingAddresses"
                >
                  {{ address.address }}
                </option>
              </select>
            </ng-container>
          </div>
          <button
            class="col-md-3 col-sm-12 col-xs-12 mt-2 mx-3 btn btn-outline-primary"
            (click)="open(content)"
          >
            {{ "cart.order.new_billing" | translate }}
          </button>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-md-3 col-sm-12 col-xs-12 mt-2">
            <select
              class="form-control form-select col-md-4 col-sm-12 col-xs-12 mt-2"
              [(ngModel)]="selectedCard"
              (change)="get_prices()"
            >
              <option [ngValue]="null">
                {{ "card.new_card" | translate }}
              </option>
              <option *ngFor="let card of cards" [ngValue]="card">
                {{ card.card_brand }} | xxxx-xxxx-xxxx-{{ card.card_last_four }}
              </option>
            </select>
          </div>

          <button
            class="col-md-3 col-sm-12 col-xs-12 mx-3 btn btn-outline-success"
            [disabled]="
              disableOrderBtn ||
              BillingAddresses.length == 0 ||
              !selectedBillingAddressId ||
              !check_1 ||
              !check_2 ||
              !check_3
            "
            (click)="webOrder()"
          >
            {{ "cart.order.complete" | translate }}
          </button>
        </div>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="container">
        <CLM-billing-data
          (dataSend)="addBillingAddress($event)"
        ></CLM-billing-data>
      </div>
    </ng-template>

    <ng-template #registerContent let-modal>
      <CLM-register (registered)="onUserRegister()"></CLM-register>
    </ng-template>
  </div>
</div>
