<form [formGroup]="billingForm" (ngSubmit)="onSubmit()">
  <h1 class="text-center m-2 p-2 fs-1 fw-bold">
    {{ "billing_address.new" | translate }}
  </h1>
  <div class="row mt-3">
    <div class="col-12 mt-3">
      <label for="billing-data-country" class="form-label">{{
        "billing_address.country" | translate
      }}</label>
      <select
        class="form-select"
        formControlName="country"
        id="billing-data-country"
      >
        <option value="{{ country.id }}" *ngFor="let country of countries">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="col-12 mt-3">
      <label for="billing-data-zipcode" class="form-label">{{
        "billing_address.zip_code" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="billing-data-zipcode"
        formControlName="zipcode"
      />
    </div>
    <div class="col-12 mt-3">
      <label for="billing-data-city" class="form-label">{{
        "billing_address.city" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="billing-data-city"
        formControlName="city"
      />
    </div>
    <div class="col-12 mt-3">
      <label for="billing-data-address" class="form-label">{{
        "billing_address.address" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="billing-data-address"
        formControlName="address"
      />
    </div>
    <div class="col-12 mt-3">
      <label for="name" class="form-label">{{
        "billing_address.place_type" | translate
      }}</label>
      <select
        class="form-select"
        formControlName="placetype"
        id="billing-data-placetype"
      >
        <option value="{{ placetype.id }}" *ngFor="let placetype of placeTypes">
          {{ placetype.name }}
        </option>
      </select>
    </div>

    <div class="col-12 mt-3">
      <label for="billing-data-housenumber" class="form-label">{{
        "billing_address.house_number" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="billing-data-housenumber"
        formControlName="housenumber"
      />
    </div>
  </div>
  <div class="row justify-content-center mt-5 mb-2 mx-3">
    <button
      class="btn btn-outline-primary"
      (click)="dataSend.emit(billingForm)"
      [disabled]="!billingForm.valid"
    >
      {{ "basic.save" | translate }}
    </button>
  </div>
</form>
