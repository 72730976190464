import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../../Core/Services/Api/api.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../Core/Toast/toast.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  host: {
    class: 'flex-shrink-0 flex-grow-1',
  },
  imports: [CommonModule, FormsModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  secret: string = '';
  email: string = '';
  password: string = '';
  succesfullReset: boolean = false;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    route.paramMap.subscribe({
      next: (param) => {
        this.secret = String(param.get('secret'));
      },
    });
  }

  resetPassword() {
    this.apiService
      .resetPassword(this.email, this.password, this.secret)
      .subscribe({
        next: (response) => {
          if (response.result) {
            this.succesfullReset = true;
          } else {
            this.toastService.showAlert('Invalid password changing!');
          }
        },
      });
  }
}
