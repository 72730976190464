<div class="welcome-container">
  <div class="title-section">
    <h1 class="welcome-title" [innerHTML]="welcomeText"></h1>
    <div class="vps-btn-container">
      <button class="vps_btn" [routerLink]="['vps']">
        {{ "home-page.welcome-section.vps-btn" | translate }}
      </button>
    </div>
  </div>
</div>

<i
  class="bi bi-chevron-down down-icon"
  (click)="scrollService.scrollTo('infoSection')"
></i>
