import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ScrollService } from '../../../Core/Services/Scroll/scroll.service';

@Component({
  selector: '[app-info-section]',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './info-section.component.html',
  styleUrl: './info-section.component.css',
})
export class InfoSectionComponent implements OnInit, OnDestroy {
  constructor(public scrollService: ScrollService) {}

  resizeObserver: ResizeObserver = new ResizeObserver((entiries, observer) => {
    this.resize();
  });

  ngOnInit(): void {
    this.resize();
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  resize() {
    const infoPanels = document.querySelectorAll('.info_panel');
    this.resizeObserver.observe(infoPanels[0]);
    infoPanels.forEach((infoPanel) => {
      let element = infoPanel as HTMLElement;
      element.style.setProperty(
        '--bg_height',
        infoPanel.clientHeight / 2 + 'px'
      );
    });
  }
}
