import { Component } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { UserService } from "../../../Core/Services/User/user.service";
import { Order, OrderData } from "../../../Core/models/Order";
import { CommonModule } from "@angular/common";
import { OrderComponent } from "./order/order.component";
import { TranslateModule } from "@ngx-translate/core";
import { OrderDataComponent } from "./order-data/order-data.component";
import { Card } from "../../../Core/models/Card";

@Component({
  selector: "app-orders",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [CommonModule, TranslateModule, OrderDataComponent],
  templateUrl: "./orders.component.html",
  styleUrl: "./orders.component.scss",
})
export class OrdersComponent {
  orderDatas: OrderData[] = [];
  orders: Order[] = [];
  cards: Card[] = [];
  loaded = false;
  displayArchive = false;
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    apiService.getUserCards().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
          return;
        }
        if (response.data) {
          this.cards = response.data;
          this.apiService.getOrdersData().subscribe({
            next: (response) => {
              this.loaded = true;
              if (response.data) {
                this.orderDatas = response.data;
              }
            },
          });
        }
      },
    });
  }
}
