<ng-container *ngIf="(vps?.sts == 'C' || vps?.sts == 'U') && vps_info">
  <div class="container border my-2">
    <div
      class="alert my-2 cursor-pointer"
      [ngClass]="vps?.sts == 'C' ? 'alert-success' : 'alert-secondary'"
      role="alert"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center">
            {{ vps?.name }} |
            {{ "vps.power_states." + vps_info.power_state | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="vps_info?.power_state">
      <div class="row justify-content-center">
        <div
          class="col-xl-6 col-lg-6 order-2 order-xl-1 order-md-2 col-md-12 col-12 text-center"
        >
          <table class="table table-striped text-center">
            <tbody>
              <tr *ngIf="vps_info?.os_version && vps_info?.os_version != []">
                <td>{{ "vps.os" | translate }}</td>
                <td>
                  {{ vps_info.os_version?.distro | capitalize }}
                  <fa-icon
                    [icon]="InfoIcon"
                    class="text-success"
                    [ngbTooltip]="vps_info.os_version.name"
                  ></fa-icon>
                </td>
              </tr>
              <tr
                *ngIf="
                  vps_info?.startTime && vps_info?.power_state == 'Running'
                "
              >
                <td>{{ "vps.running_time" | translate }}</td>
                <td>{{ runningTime | time }}</td>
              </tr>
              <tr *ngIf="vps_info?.cpunumber">
                <td>{{ "vps.cpu_number" | translate }}</td>
                <td>{{ vps_info.cpunumber }}</td>
              </tr>
              <tr *ngIf="vps_info?.disksize || true">
                <td>{{ "vps.disk" | translate }}</td>
                <td>
                  {{
                    (vps_info
                      ? vps_info.disksize
                        ? vps_info.disksize / 1024 / 1024 / 1024
                        : 0
                      : "missing"
                    ) | number : "1.0-0"
                  }}
                  GB
                </td>
              </tr>
              <tr *ngIf="vps_info?.cpunumber">
                <td>{{ "vps.memory" | translate }}</td>
                <td>
                  {{
                    (vps_info ? vps_info.memorysize / 1024 / 1024 / 1024 : 0)
                      | number : "1.0-0"
                  }}
                  GB
                </td>
              </tr>
              <tr *ngIf="vps_info?.addresses?.['0/ipv4/0']">
                <td>{{ "vps.IPV4" | translate }}</td>
                <td>
                  {{ vps_info.addresses["0/ipv4/0"] }}
                  <ng-container *ngIf="vps_info?.addresses?.['0/ipv4/1']">
                    <br />
                    {{ vps_info.addresses["0/ipv4/1"] }}
                  </ng-container>
                  <ng-container *ngIf="vps_info?.addresses?.['0/ipv4/2']">
                    <br />
                    {{ vps_info.addresses["0/ipv4/2"] }}
                  </ng-container>
                </td>
              </tr>
              <tr *ngIf="vps_info?.addresses?.['0/ipv6/0']">
                <td>{{ "vps.IPV6" | translate }}</td>
                <td>
                  {{ vps_info.addresses["0/ipv6/0"] }}
                  <ng-container *ngIf="vps_info?.addresses?.['0/ipv6/1']">
                    <br />
                    {{ vps_info.addresses["0/ipv6/1"] }}
                  </ng-container>
                  <ng-container *ngIf="vps_info?.addresses?.['0/ipv6/2']">
                    <br />
                    {{ vps_info.addresses["0/ipv6/2"] }}
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-xl-6 order-1 order-xl-2 col-lg-6 order-lg-2 order-md-1 col-12 col-md-12 text-center mt-1 mb-5"
        >
          <div class="btn-group">
            <button
              type="button"
              class="btn"
              [ngClass]="
                canStartVps() ? 'btn-outline-success' : 'btn-outline-secondary'
              "
              [disabled]="!canStartVps()"
              ngbTooltip="{{ 'vps.functions.start' | translate }}"
              (click)="startVps()"
            >
              <div *ngIf="!start">
                <fa-icon [icon]="startIcon"></fa-icon>
              </div>
              <div class="spinner-border spinner-border-sm" *ngIf="start"></div>
            </button>

            <button
              type="button"
              class="btn"
              [ngClass]="
                canStopVps() ? 'btn-outline-danger' : 'btn-outline-secondary'
              "
              [disabled]="!canStopVps()"
              ngbTooltip="{{ 'vps.functions.stop' | translate }}"
              (click)="stopVps()"
            >
              <fa-icon *ngIf="!clean_shutdown" [icon]="stopIcon"></fa-icon>
              <div
                class="spinner-border spinner-border-sm"
                *ngIf="clean_shutdown"
              ></div>
            </button>
            <button
              type="button"
              class="btn"
              [ngClass]="
                canForceStopVps()
                  ? 'btn-outline-danger'
                  : 'btn-outline-secondary'
              "
              [disabled]="!canForceStopVps()"
              ngbTooltip="{{ 'vps.functions.forceStop' | translate }}"
              (click)="forceStopVps()"
            >
              <ng-container *ngIf="!hard_shutdown">
                <fa-icon [icon]="forceStopIcon"></fa-icon>
              </ng-container>
              <div
                class="spinner-border spinner-border-sm"
                *ngIf="hard_shutdown"
              ></div>
            </button>
            <button
              type="button"
              class="btn"
              [ngClass]="
                canRebootVps() ? 'btn-outline-warning' : 'btn-outline-secondary'
              "
              [disabled]="!canRebootVps()"
              ngbTooltip="{{ 'vps.functions.reboot' | translate }}"
              (click)="rebootVps()"
            >
              <ng-container *ngIf="!reboot">
                <fa-icon [icon]="rebootIcon"></fa-icon>
              </ng-container>
              <div
                class="spinner-border spinner-border-sm"
                *ngIf="reboot"
              ></div>
            </button>
            <button
              type="button"
              class="btn"
              [ngClass]="
                canForceRebootVps()
                  ? 'btn-outline-warning'
                  : 'btn-outline-secondary'
              "
              ngbTooltip="{{ 'vps.functions.forceReboot' | translate }}"
              [disabled]="!canForceRebootVps()"
              (click)="forceRebootVps()"
            >
              <ng-container *ngIf="!hard_reboot">
                <fa-icon [icon]="forceIcon"></fa-icon>
                <fa-icon [icon]="rebootIcon"></fa-icon>
              </ng-container>
              <div
                class="spinner-border spinner-border-sm"
                *ngIf="hard_reboot"
              ></div>
            </button>
            <button
              class="btn btn-outline-primary"
              (click)="openConsole()"
              *ngIf="canStopVps()"
            >
              Console Panel
            </button>
          </div>

          <span *ngIf="operation"
            >{{ "vps.operations." + operation | translate }}
            <div class="spinner-border spinner-border-sm"></div
          ></span>
          <div class="mt-5" *ngIf="isCEVPS">
            <button
              class="btn btn-outline-primary"
              (click)="vpsProxy('ON')"
              *ngIf="vps_info.proxy == undefined || vps_info.proxy == 'OFF'"
            >
              {{ "vps.proxy.on" | translate }}
            </button>
            <button
              class="btn btn-outline-danger"
              (click)="vpsProxy('OFF')"
              *ngIf="vps_info.proxy == 'ON'"
            >
              {{ "vps.proxy.off" | translate }}
            </button>
            <fa-icon
              class="mx-5 fs-4 text-success cursor-pointer"
              [icon]="InfoIcon"
              (click)="openProxyInfo()"
            ></fa-icon>
            <br />
            <div class="input-group mt-4" *ngIf="vps_info.proxy == 'ON'">
              <span class="input-group-text" id="proxy_domain">Domain</span>
              <input
                type="text"
                class="form-control"
                aria-describedby="proxy_domain"
                [(ngModel)]="proxyDomain"
              />
              <button class="btn btn-primary" (click)="onProxyDomainChange()">
                {{ "vps.proxy.change" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(vps?.sts == 'R' || vps?.sts == 'L') && vps_info">
  <div class="container border my-2">
    <div
      class="alert my-2 cursor-pointer"
      [ngClass]="vps?.sts == 'R' ? 'alert-success' : 'alert-secondary'"
      role="alert"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center">{{ vps?.name }}</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-xl-6 col-lg-6 order-2 order-xl-1 order-md-2 col-md-12 col-12 text-center"
        >
          <table class="table table-striped text-center">
            <tbody>
              <tr *ngIf="vps_info?.Accesslink">
                <td>Accesslink</td>
                <td>{{ vps_info.Accesslink }}</td>
              </tr>
              <tr *ngIf="vps_info?.IpAddress">
                <td>IpAddress</td>
                <td>{{ vps_info.IpAddress }}</td>
              </tr>
              <tr *ngIf="vps_info.Port">
                <td>Port</td>
                <td>{{ vps_info.Port }}</td>
              </tr>
              <tr *ngIf="vps_info.Username">
                <td>Username</td>
                <td>{{ vps_info.Username }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
