import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../Core/Toast/toast.service";
import { ApiService } from "../../Core/Services/Api/api.service";

@Component({
  selector: "[app-contact-section]",
  standalone: true,
  imports: [TranslateModule, FormsModule],
  templateUrl: "./contact-section.component.html",
  styleUrl: "./contact-section.component.css",
})
export class ContactSectionComponent {
  constructor(
    private toastService: ToastService,
    private apiService: ApiService,
    private translate: TranslateService
  ) {}
  name: string = "";
  email: string = "";
  subject: string = "";
  message: string = "";

  sendMessage() {
    if (this.name && this.email && this.subject && this.message) {
      this.apiService
        .sendMessage(this.name, this.email, this.subject, this.message)
        .subscribe({
          next: (response) => {
            if (response.result) {
              this.translate
                .get("basic.message-sent-success")
                .subscribe((message) => {
                  this.toastService.showAlert(message);
                });
            } else {
              this.translate
                .get("basic.message-sent-fail")
                .subscribe((message) => {
                  this.toastService.showAlert(message);
                });
            }
            this.name = "";
            this.email = "";
            this.subject = "";
            this.message = "";
          },
        });
    } else {
    }
  }
}
