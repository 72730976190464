import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import { Router, RouterLink } from "@angular/router";

import ScrollReveal from "scrollreveal";
import { ToastService } from "../../Core/Toast/toast.service";
import { ApiService } from "../../Core/Services/Api/api.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { UserService } from "../../Core/Services/User/user.service";
import { ConfirmDialogService } from "../../Core/Services/ConfirmDialog/confirm-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { WelcomeSectionComponent } from "./welcome-section/welcome-section.component";
import { InfoSectionComponent } from "./info-section/info-section.component";

@Component({
  selector: "app-main",
  standalone: true,
  imports: [RouterLink, WelcomeSectionComponent, InfoSectionComponent],
  templateUrl: "./main.component.html",
  styleUrl: "./main.component.scss",
})
export class MainComponent implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  welcomeText?: SafeHtml;
  vps_desc?: SafeHtml;
  ds_desc?: SafeHtml;
  wh_desc?: SafeHtml;

  onLangChange?: Subscription;

  ngOnInit(): void {
    const config = {
      reset: true, // Visszaállítás, amikor az elem ismét láthatóvá válik
      origin: "bottom", // Animáció iránya
      distance: "20px", // Az elem elmozdulásának mértéke
      duration: 2000, // Animáció időtartama
      delay: 0, // Késleltetés az animáció előtt
      easing: "cubic-bezier(0.5, 0, 0, 1)", // Animáció könnyítése
      scale: 1, // Méretezési érték
    };
    ScrollReveal().reveal(
      this.elementRef.nativeElement.querySelectorAll(".reveal"),
      config
    );

    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.getApiCalls();
    });

    this.getApiCalls();
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  getApiCalls() {
    this.apiService.getWebText("homepage_text").subscribe({
      next: (response) => {
        this.welcomeText = this.sanitizer.bypassSecurityTrustHtml(
          response.data[0].html
        );
      },
    });
    this.apiService.getWebText("homepage_vps_description").subscribe({
      next: (response) => {
        this.vps_desc = this.sanitizer.bypassSecurityTrustHtml(
          response.data[0].html
        );
      },
    });
    this.apiService.getWebText("homepage_wh_description").subscribe({
      next: (response) => {
        this.wh_desc = this.sanitizer.bypassSecurityTrustHtml(
          response.data[0].html
        );
      },
    });
    this.apiService.getWebText("homepage_ds_description").subscribe({
      next: (response) => {
        this.ds_desc = this.sanitizer.bypassSecurityTrustHtml(
          response.data[0].html
        );
      },
    });
  }
}
