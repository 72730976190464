<div class="container">
  <div *ngIf="!result" class="mt-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div *ngIf="result && success" class="mt-5">
    <h2 class="text-center">{{ "register.confirm.success" | translate }}</h2>
  </div>
  <div *ngIf="result && !success" class="mt-5">
    <h2 class="text-center">{{ "register.confirm.unsuccess" | translate }}</h2>
  </div>
</div>
