import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { PlaceType } from "../../../../Core/models/PlaceType";
import { Country } from "../../../../Core/models/Country";
import { CommonModule } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { BillingData } from "../../../../Core/models/BillingData";
import { NgbTypeahead, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "CLM-billing-data",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    TranslateModule,
  ],
  templateUrl: "./billing-data.component.html",
  styleUrl: "./billing-data.component.scss",
})
export class BillingDataComponent implements OnInit {
  constructor(private apiService: ApiService) {}
  @Output() dataSend = new EventEmitter<FormGroup>();
  @ViewChild("instance", { static: true }) instance: NgbTypeahead;

  placeTypes: PlaceType[] = [];
  countries: Country[] = [];

  public billingForm = new FormGroup({
    country: new FormControl("", [Validators.required]),
    zipcode: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    placetype: new FormControl("", [Validators.required]),
    address: new FormControl("", [Validators.required]),
    housenumber: new FormControl("", [Validators.required]),
  });

  ngOnInit(): void {
    this.apiService.getPlaceTypes().subscribe({
      next: (response) => {
        if (response.data) this.placeTypes = response.data;
      },
      error: (error) => {},
      complete: () => {},
    });
    this.apiService.getCountries().subscribe({
      next: (response) => {
        if (response.data) this.countries = response.data;
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  onSubmit(): void {}

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly Country[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? this.countries
          : this.countries.filter(
              (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
      )
    );
  };

  countryToString(item: any) {
    return item.name;
  }
}
