<div class="container">
  <CLM-user-data [google]="google"></CLM-user-data>
  <div class="row mt-3 justify-content-center">
    <button
      class="btn btn-outline-danger mt-5 mb-5 col-6"
      [disabled]="!user?.userForm?.valid"
      (click)="register()"
    >
      {{ "register.register" | translate }}
    </button>
  </div>
</div>
