<form [formGroup]="code">
  <div class="container mb-5">
    <h2 class="text-center m-2 p-2 fs-1 fw-bold">Activation Code</h2>
    <div class="row justify-content-center">
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_1"
          (input)="next_input('c_1', 'c_2')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_1"
          [ngClass]="
            code.controls['c_1'].value
              ? 'border-success'
              : code.controls['c_1'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_2"
          (input)="next_input('c_2', 'c_3')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_2"
          [ngClass]="
            code.controls['c_2'].value
              ? 'border-success'
              : code.controls['c_2'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_3"
          (input)="next_input('c_3', 'c_4')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_3"
          [ngClass]="
            code.controls['c_3'].value
              ? 'border-success'
              : code.controls['c_3'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_4"
          (input)="next_input('c_4', 'c_5')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_4"
          [ngClass]="
            code.controls['c_4'].value
              ? 'border-success'
              : code.controls['c_4'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_5"
          (input)="next_input('c_5', 'c_6')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_5"
          [ngClass]="
            code.controls['c_5'].value
              ? 'border-success'
              : code.controls['c_5'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_6"
          (paste)="onPaste($event)"
          (input)="next_input('', '')"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_6"
          [ngClass]="
            code.controls['c_6'].value
              ? 'border-success'
              : code.controls['c_6'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
    </div>
  </div>
</form>
