<div class="container">
  <ng-container *ngIf="!succesfullReset">
    <div class="row m-5 justify-content-center">
      <div class="input-group mb-3">
        <span class="input-group-text">Email</span>
        <input
          id="email"
          class="form-control"
          type="text"
          [(ngModel)]="email"
        />
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Password</span>
        <input
          id="password"
          class="form-control"
          type="password"
          [(ngModel)]="password"
        />
      </div>
      <button
        class="btn btn-outline-danger mt-2 mb-5 col-6"
        (click)="resetPassword()"
      >
        Send
      </button>
    </div></ng-container
  >
  <ng-container *ngIf="succesfullReset">
    <h2 class="mt-5 text-center">Password Changed!</h2>
  </ng-container>
</div>
