<div class="container mt-5" *ngIf="!messageSent">
  <div class="row justify-content-center mt-5">
    <div class="col-3 text-center">
      <div class="my-2">
        <span>{{ "support.title" | translate }}</span>
      </div>
      <select
        class="form-control border border-primary"
        [(ngModel)]="selected_type"
        (change)="selectChanged()"
      >
        <option *ngFor="let type of types" [ngValue]="type">
          {{ type.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="row justify-content-center mt-5" *ngIf="subTypes.length > 0">
    <div class="col-3 text-center">
      <div [ngSwitch]="selected_type?.type" class="my-2">
        <span *ngSwitchCase="'vps'" class="my-3">{{
          "support.select_title.vps" | translate
        }}</span>
        <span *ngSwitchCase="'order'" class="my-3">{{
          "support.select_title.order" | translate
        }}</span>
        <span *ngSwitchCase="'invoice'" class="my-3">{{
          "support.select_title.invoice" | translate
        }}</span>
      </div>
      <select
        class="form-control border border-primary"
        [(ngModel)]="selected_sub_type"
      >
        <option *ngFor="let subType of subTypes" [ngValue]="subType">
          {{ subType.name }}
        </option>
      </select>
    </div>
  </div>
  <textarea
    [disabled]="!selected_type || (subTypes.length > 0 && !selected_sub_type)"
    name=""
    id=""
    cols="30"
    rows="10"
    class="form-control mt-5"
    [(ngModel)]="message"
    placeholder="{{ 'support.message_placeholder' | translate }}"
  ></textarea>
  <div class="row justify-content-center">
    <button
      class="btn btn-primary col-3 mt-5"
      (click)="sendSupportMessage()"
      [disabled]="!selected_type || (subTypes.length > 0 && !selected_sub_type)"
    >
      {{ "support.send" | translate }}
    </button>
  </div>
</div>
<div class="container mt-5" *ngIf="messageSent">
  <div class="row justify-content-center mt-5">
    <h1 class="col-6 text-center mt-5">
      {{ "support.message_sent" | translate }}
    </h1>
  </div>
</div>
