import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NumberSelectComponent } from "../../../../Core/Components/number-select/number-select.component";
import { CheckboxInputComponent } from "../../../../Core/Components/checkbox-input/checkbox-input.component";
import { StringSelectComponent } from "../../../../Core/Components/string-select/string-select.component";
import { CartExtra, CartItem } from "../../../../Core/models/Cart";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { FormsModule } from "@angular/forms";
import { Subscription } from "rxjs";
import { CartService } from "../../../../Core/Services/Cart/cart-service";

@Component({
  selector: "app-config-panel",
  standalone: true,
  imports: [
    CommonModule,
    NumberSelectComponent,
    CheckboxInputComponent,
    StringSelectComponent,
    TranslateModule,
    NgbTooltipModule,
    FormsModule,
  ],
  templateUrl: "./config-panel.component.html",
  styleUrl: "./config-panel.component.css",
})
export class ConfigPanelComponent implements OnInit, OnDestroy {
  onLangChange?: Subscription;
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private cartService: CartService
  ) {}
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  ngOnInit(): void {
    this.priceTypeS = this.cartService.Cart.period ? true : false;
    this.onLangChange = this.translateService.onLangChange.subscribe(() => {
      this.apiCall();
    });
    this.apiCall();
  }

  apiCall() {
    this.apiService.getPriceTypes().subscribe({
      next: (response) => {
        this.pricetypes = response.data;
        this.extraValueChanged();
        let ids = this.cartService.getIds();
        ids.push(this.id);
        this.apiService.getPriceTypesInProducts(ids).subscribe({
          next: (response) => {
            this.avaibalePeriods = response.data;
            if (
              this.priceTypeS &&
              this.cartService.Cart.period &&
              !this.isAvailable(this.cartService.Cart.period)
            ) {
              this.cantAddToCart.emit(true);
            }
          },
        });
      },
    });
  }

  isAvailable(id: number) {
    return this.avaibalePeriods.findIndex((o) => o.pricetypeid == id) != -1;
  }

  @Input() params: any;
  @Input() extras: any;
  @Input() title: string;
  @Input() id: number;
  @Input() period: number = 1;

  @Output() onExtraChange: EventEmitter<CartExtra[]> = new EventEmitter<
    CartExtra[]
  >();
  @Output() periodChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() cantAddToCart: EventEmitter<boolean> = new EventEmitter<boolean>();

  Extras: CartExtra[] = [];
  pricetypes: any[] = [];
  avaibalePeriods: { pricetypeid: number }[] = [];
  priceTypeS: boolean = false;

  extraValueChanged() {
    this.Extras = [];
    document.querySelectorAll(".input-extra").forEach((element) => {
      if (element instanceof HTMLSelectElement) {
        if (element.classList.contains("check")) {
          if (element.options[element.selectedIndex].value == "1") {
            this.Extras.push({
              productid: +element.name,
              quantity: 1,
              type: "chek",
            });
          }
        } else {
          this.Extras.push({
            productid: +element.options[element.selectedIndex].value,
            quantity: 1,
            type: "select",
          });
        }
      } else if (element instanceof HTMLInputElement) {
        if (element.value !== "0") {
          this.Extras.push({
            productid: +element.name,
            quantity: +element.value,
            type: "csuszka",
          });
        }
      }
    });
    this.onExtraChange.emit(this.Extras);
  }
}
