import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  transform(value: number | undefined, args?: any): string {
    if (!value) return '';
    let secs = Math.floor(value / 1000) % 60;
    let mins = Math.floor(value / 1000 / 60) % 60;
    let hours = Math.floor(value / 1000 / 60 / 60);
    let days = Math.floor(value / 1000 / 60 / 60 / 24);
    return (
      String(hours) +
      ':' +
      String(mins).padStart(2, '0') +
      ':' +
      String(secs).padStart(2, '0')
    );
  }
}
