import {} from "@angular/common/http";
import { Component, inject, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { SuccessModalComponent } from "../cart/success-modal/success-modal.component";
import { Router } from "@angular/router";
import { SubscriptionCancelComponent } from "../user/subscriptions/subscription-cancel/subscription-cancel.component";
import { ApiService } from "../../Core/Services/Api/api.service";
import { ScriptService } from "../../Core/Services/Script/script.service";

import { UserService } from "../../Core/Services/User/user.service";
import { TranslateService } from "@ngx-translate/core";
import { ClmCookieService } from "../../Core/Services/Cookie/clm-cookie.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CurrencyService } from "../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-test",
  standalone: true,
  imports: [FormsModule, SubscriptionCancelComponent],
  templateUrl: "./test.component.html",
  styleUrl: "./test.component.scss",
})
export class TestComponent implements OnInit {
  google: string = "";

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.google = navigation.extras.state["google"];
    }
  }

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

  ngOnInit(): void {
    console.log(this.decodeToken(this.google));
  }
}
