import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  constructor(private activeModal: NgbActiveModal) {}
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() confirmText: string = 'Yes';
  @Input() declineText: string = 'No';
  confirm() {
    this.activeModal.close(true);
  }
  decline() {
    this.activeModal.close(false);
  }
}
