<ng-container [ngSwitch]="emailSent">
  <div class="container" *ngSwitchCase="false">
    <div class="row m-5 justify-content-center">
      <div class="input-group mb-3">
        <span class="input-group-text">Email</span>
        <input
          id="email"
          class="form-control"
          type="text"
          [(ngModel)]="email"
        />
      </div>
      <div class="alert alert-warning" role="alert" *ngIf="error_message">
        {{ error_message }}
      </div>
      <button
        class="btn btn-outline-danger mt-2 mb-5 col-6"
        (click)="emailSend()"
      >
        Send
      </button>
    </div>
  </div>
  <div class="container" *ngSwitchCase="true">
    <div class="row m-5 justify-content-center">
      <h2 class="text-center">Email sent</h2>
    </div>
  </div>
</ng-container>
