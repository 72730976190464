<div class="container my-5">
  <div class="input-group mb-3">
    <span class="input-group-text">{{
      "subscription-page.select" | translate
    }}</span>
    <select [(ngModel)]="selectedSubscription" class="form-control form-select">
      <option [ngValue]="sub" *ngFor="let sub of subscriptions">
        {{ sub.name }}
      </option>
    </select>
  </div>

  <app-subscription
    [subscription]="selectedSubscription"
    *ngIf="selectedSubscription"
  ></app-subscription>
</div>
