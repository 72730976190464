import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../../Core/Toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent implements OnInit {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}
  ngOnInit(): void {
    this.toastService.showAlert('Page Not Found');
    this.router.navigate(['/']);
  }
}
