import { Component, OnInit, ViewChild, viewChild } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { VpsComponent } from "./vps/vps.component";
import { VPS } from "../../../Core/models/VPS";
import { UserService } from "../../../Core/Services/User/user.service";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-services",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [CommonModule, VpsComponent, TranslateModule],
  templateUrl: "./services.component.html",
  styleUrl: "./services.component.scss",
})
export class ServicesComponent {
  @ViewChild(VpsComponent) vpsComponent?: VpsComponent;
  services?: VPS[] = [];
  loaded: boolean = false;
  selectedService?: VPS;
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    apiService.getVPSes().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        if (response.result) {
          this.services = response.data?.filter(
            (x) => x.sts == "C" || x.sts == "U" || x.sts == "R" || x.sts == "L"
          );
          if (this.services && this.services.length > 0) {
            this.selectedService = this.services[0];
          }
        }
      },
    });
  }

  serviceSelected(service: VPS) {
    this.loaded = false;
    this.selectedService = service;
    if (this.vpsComponent) {
      this.vpsComponent.vps = service;
    }
    this.vpsComponent?.ngOnDestroy();
    this.vpsComponent?.ngOnInit();
  }

  onLoaded() {
    this.loaded = true;
  }
}
