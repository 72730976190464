import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../../Core/Services/Api/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  host: {
    class: 'flex-shrink-0 flex-grow-1',
  },
  imports: [CommonModule, FormsModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  constructor(private apiService: ApiService) {}
  emailSent: boolean = false;
  email: string = '';
  error_message: String = '';
  emailSend() {
    this.apiService.forgotPassword(this.email).subscribe({
      next: (response) => {
        if (response.result) {
          this.emailSent = true;
        } else {
          this.error_message = response.error;
        }
      },
    });
  }
}
