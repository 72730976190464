import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { CommonModule } from "@angular/common";
import { ConfigPanelComponent } from "./config-panel/config-panel.component";
import { PricePanelComponent } from "./price-panel/price-panel.component";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CartExtra, CartItem } from "../../../Core/models/Cart";
import { CartService } from "../../../Core/Services/Cart/cart-service";
import { CurrencyService } from "../../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-vps-config-page",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ConfigPanelComponent,
    PricePanelComponent,
  ],
  templateUrl: "./vps-config-page.component.html",
  styleUrl: "./vps-config-page.component.css",
})
export class VpsConfigPageComponent implements OnInit, OnDestroy {
  onLangChange?: Subscription;
  onCurrencyChange?: Subscription;
  paramsub: Subscription;
  categoryName: string = "";
  productName: string = "";

  vpsData: any;

  nettoPrice: number = 0;
  bruttoPrice: number = 0;
  afaPrice: number = 0;

  extras: CartExtra[] = [];

  selectedPeriod = 1;
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private cartService: CartService,
    public currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.selectedPeriod = this.cartService.Cart.period
      ? this.cartService.Cart.period
      : 1;
    this.paramsub = this.route.params.subscribe((params) => {
      this.categoryName = params["category"];
      this.productName = params["product"];
      this.apiCall();
    });
    this.onCurrencyChange = this.currencyService.onChange.subscribe(() => {
      this.getPrices();
    });
    this.onLangChange = this.translateService.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  cantAddToCart(cantAddToCart: boolean) {
    if (cantAddToCart) {
      this.canAddToCart = false;
    }
  }

  canAddToCart: boolean = true;

  apiCall(): void {
    this.apiService.getProductTree()?.subscribe({
      next: (data) => {
        let category = data[0].sub_categories.find((category) => {
          return category.name === this.categoryName;
        });
        if (!category) {
          this.router.navigate(["/vps"]);
          return;
        }
        let product = category?.products.find((product) => {
          return product.sname === this.productName;
        });
        if (!product) {
          this.router.navigate(["/vps"]);
          return;
        }
        this.apiService.getProductData(product.id)?.subscribe({
          next: (data) => {
            this.vpsData = data[0];
          },
        });
      },
    });
  }
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.paramsub?.unsubscribe();
  }

  periodChanged(event: number) {
    this.selectedPeriod = event;
    let cartItem: CartItem = new CartItem();
    cartItem.productid = this.vpsData.id;
    cartItem.quantity = 1;
    cartItem.extras = this.extras;
    this.apiService.priceCalc([cartItem], this.selectedPeriod).subscribe({
      next: (response) => {
        if (response.result) {
          this.nettoPrice = response.data.sumpricenetto;
          this.bruttoPrice = response.data.sumpricebrutto;
          this.afaPrice = response.data.sumpriceafa;
        }
      },
    });
  }

  getPrices() {
    let cartItem: CartItem = new CartItem();
    cartItem.productid = this.vpsData.id;
    cartItem.quantity = 1;
    cartItem.extras = this.extras;
    this.apiService.priceCalc([cartItem], this.selectedPeriod).subscribe({
      next: (response) => {
        if (response.result) {
          this.nettoPrice = response.data.sumpricenetto;
          this.bruttoPrice = response.data.sumpricebrutto;
          this.afaPrice = response.data.sumpriceafa;
        }
      },
    });
  }

  extraValueChanged(event: CartExtra[]) {
    this.extras = event;
    this.getPrices();
  }

  addToCart() {
    this.extras.forEach((element) => {
      if (element.type == "chek") {
        let extra = this.vpsData.productextra.find((extra: any) => {
          if (extra.type == "chek") {
            let item = extra.items.find((item: any) => {
              return item.id == element.productid;
            });
            return item;
          }
          return false;
        });

        element.name = extra.name;
      }
      if (element.type == "csuszka") {
        let extra = this.vpsData.productextra.find((extra: any) => {
          if (extra.type == "csuszka") {
            let item = extra.items.find((item: any) => {
              return item.id == element.productid;
            });
            return item;
          }
          return false;
        });
        element.name = extra.name;
        element.item = extra.items[0].mename;
      }
      if (element.type == "select") {
        let extra = this.vpsData.productextra.find((extra: any) => {
          if (extra.type == "select") {
            let item = extra.items.find((item: any) => {
              return item.id == element.productid;
            });
            return item;
          }
          return false;
        });

        let item = extra.items.find((item: any) => {
          return item.id == element.productid;
        });
        element.name = extra.name;
        element.item = item.name;
      }
    });
    let Item: CartItem = {
      productid: this.vpsData.id,
      quantity: 1,
      product: this.vpsData,
      extras: this.extras,
      name: this.vpsData.name,
      params: this.vpsData.params,
    };
    this.cartService.addToCart(Item, this.selectedPeriod);
    this.router.navigate(["/cart"]);
  }
}
