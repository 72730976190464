<div class="number-select">
  <input
    class="input-extra"
    [attr.data-cartname]="cartName"
    type="number"
    [value]="0"
    [min]="0"
    [max]="max"
    [step]="step"
    [name]="name"
    (change)="onInputChange($event)"
  />
</div>
