import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { FormGroup, FormsModule } from "@angular/forms";
import { Country } from "../../../../Core/models/Country";
import { PlaceType } from "../../../../Core/models/PlaceType";
import { BillingDataComponent } from "../../register/billing-data/billing-data.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogService } from "../../../../Core/Services/ConfirmDialog/confirm-dialog.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

export class Address {
  id?: number;
  customerid?: number;
  addressname?: string;
  city?: string;
  countryid?: number;
  housenr?: string;
  irsz?: string;
  placetypeid?: number;
  street?: string;
}

@Component({
  selector: "app-billing-address",
  standalone: true,
  imports: [CommonModule, FormsModule, BillingDataComponent, TranslateModule],
  templateUrl: "./billing-address.component.html",
  styleUrl: "./billing-address.component.scss",
})
export class BillingAddressComponent implements OnInit, OnDestroy {
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private confirmDialog: ConfirmDialogService,
    private translateService: TranslateService
  ) {}
  @Output() addressChanged: EventEmitter<any> = new EventEmitter();

  selectedAddress: Address = new Address();
  addresses: Address[] = [];
  countries: Country[] = [];
  placeTypes: PlaceType[] = [];
  onLangChange?: Subscription;
  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCall();
    });
    this.apiCall();
  }
  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }
  apiCall() {
    this.apiService.getCountries().subscribe({
      next: (response) => {
        if (response.data) {
          this.countries = response.data;
        }
      },
    });
    this.apiService.getPlaceTypes().subscribe({
      next: (response) => {
        if (response.data) {
          this.placeTypes = response.data;
          this.loadBillingAddresses();
        }
      },
    });
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });
  }

  addBillingAddress(data: FormGroup) {
    this.apiService.GetCustomer().subscribe({
      next: (response) => {
        this.apiService
          .AddCustomerAddress(response.data[0].id, data)
          .subscribe({
            next: (response) => {
              this.modalService.dismissAll();
              this.loadBillingAddresses();
              this.addressChanged.emit();
            },
          });
      },
    });
  }

  loadBillingAddresses() {
    this.apiService.GetCustomerAddress().subscribe({
      next: (response) => {
        if (response.result) {
          this.addresses = response.data;
          if (this.addresses.length > 0) {
            this.selectedAddress = this.addresses[0];
          }
        }
      },
    });
  }

  deleteAddress() {
    this.translateService
      .get([
        "confirm_dialog.delete_address.title",
        "confirm_dialog.delete_address.message",
        "confirm_dialog.delete_address.confirm",
        "confirm_dialog.delete_address.decline",
      ])
      .subscribe({
        next: (translations) => {
          this.confirmDialog
            .openConfirmDialog(
              translations["confirm_dialog.delete_address.title"],
              translations["confirm_dialog.delete_address.message"],
              translations["confirm_dialog.delete_address.confirm"],
              translations["confirm_dialog.delete_address.decline"]
            )
            .subscribe({
              next: (res) => {
                if (!res) return;
                if (
                  this.selectedAddress?.id &&
                  this.selectedAddress.customerid
                ) {
                  this.apiService
                    .deleteCustomerAddress(
                      this.selectedAddress.customerid,
                      this.selectedAddress.id
                    )
                    .subscribe({
                      next: (response) => {
                        this.apiCall();
                        this.addressChanged.emit();
                      },
                    });
                }
              },
            });
        },
      });
  }
}
