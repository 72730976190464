<div class="container mt-2">
  <button
    class="btn btn-outline-secondary"
    (click)="displayArchive = !displayArchive"
  >
    {{
      displayArchive
        ? ("order.show_active" | translate)
        : ("order.show_archive" | translate)
    }}
  </button>
</div>
<div class="container my-5" *ngIf="orderDatas.length > 0">
  <app-order-data
    *ngFor="let orderData of orderDatas"
    [orderData]="orderData"
    [cards]="cards"
    [displayArchive]="displayArchive"
  ></app-order-data>
</div>

<div class="d-flex justify-content-center" style="height: 90vh" *ngIf="!loaded">
  <div
    class="spinner-grow text-primary"
    style="width: 5rem; height: 5rem; margin-top: auto; margin-bottom: auto"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-container *ngIf="orderDatas.length == 0 && loaded">
  <h2 class="text-center my-5">{{ "order.no_order" | translate }}</h2>
</ng-container>
