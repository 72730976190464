<div
  class="container border my-2"
  *ngIf="
    (!(
      (orderData?.sts == 'A' && !showArchive) ||
      (orderData?.sts == 'F' && !showFailed)
    ) &&
      !displayArchive) ||
    (displayArchive && orderData?.sts == 'A') ||
    (displayArchive && orderData?.sts == 'F')
  "
>
  <div
    class="alert mt-2"
    [ngClass]="
      orderSts == 'archive'
        ? 'alert-secondary'
        : orderSts == 'completed'
        ? 'alert-success'
        : orderSts == 'processing'
        ? 'alert-primary'
        : orderSts == 'pending'
        ? 'alert-warning'
        : orderSts == 'failed'
        ? 'alert-danger'
        : ''
    "
  >
    <h2>
      {{ orderData?.docnumber }} |
      <span class="fs-6">
        {{ "order_data.order_time" | translate }}:
        {{ orderData?.ordertime }} (CEST)
      </span>
    </h2>
  </div>
  <div class="mx-3">
    <h3>
      {{ "order_data.status.title" | translate }} :
      {{ "order_data.status." + orderSts | translate }}
      <ng-container *ngIf="orderSts == 'processing'">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </ng-container>
    </h3>
    <h3>
      {{ "order_data.payment_status.title" | translate }} :
      {{ "order_data.payment_status." + paymentSts | translate }}
      <ng-container *ngIf="paymentSts == 'checking'">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </ng-container>
    </h3>
    <ng-container *ngIf="paymentSts == 'not_paid'">
      <div class="row">
        <div class="col-3">
          <select
            class="form-control form-select col-md-4 col-sm-12 col-xs-12 mt-2"
            [(ngModel)]="selectedCard"
          >
            <option [ngValue]="null">
              {{ "card.new_card" | translate }}
            </option>
            <option *ngFor="let card of cards" [ngValue]="card">
              {{ card.card_brand }} | xxxx-xxxx-xxxx-{{ card.card_last_four }}
            </option>
          </select>
        </div>

        <button class="btn btn-outline-danger col-3" (click)="payOrder()">
          {{ "order_data.pay" | translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <table class="table my-3" *ngIf="orderData?.orderdetail">
    <thead>
      <tr>
        <th scope="col">{{ "order_data.prod_name" | translate }}</th>
        <th scope="col">{{ "order_data.quantity" | translate }}</th>
        <th scope="col">{{ "order_data.netto" | translate }}</th>
        <th scope="col">{{ "order_data.afa" | translate }}</th>
        <th scope="col">{{ "order_data.brutto" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detail of orderData?.orderdetail">
        <td>
          <span [ngClass]="detail.parentid ? 'mx-3' : ''">{{
            detail.sname
          }}</span>
        </td>
        <td>{{ detail.quantity }} {{ detail.me }}</td>
        <td>
          {{
            currencyService.printCurrencyById(
              detail.netto,
              orderData?.currencyid
            )
          }}
        </td>
        <td>
          {{
            currencyService.printCurrencyById(detail.afa, orderData?.currencyid)
          }}
        </td>
        <td>
          {{
            currencyService.printCurrencyById(
              detail.brutto,
              orderData?.currencyid
            )
          }}
        </td>
      </tr>
      <tr>
        <td colspan="5"></td>
      </tr>
      <tr>
        <td>{{ "order_data.sum" | translate }}</td>
        <td></td>
        <td>
          {{
            currencyService.printCurrencyById(
              orderData?.netto,
              orderData?.currencyid
            )
          }}
        </td>
        <td>
          {{
            currencyService.printCurrencyById(
              orderData?.afa,
              orderData?.currencyid
            )
          }}
        </td>

        <td>
          {{
            currencyService.printCurrencyById(
              orderData?.brutto,
              orderData?.currencyid
            )
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
