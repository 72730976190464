<div class="container mt-5">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <button ngbNavLink>{{ "profile.nav.userdata" | translate }}</button>
      <ng-template ngbNavContent>
        <h2 class="text-center">{{ "profile.title" | translate }}</h2>
        <div class="row justify-content-center mt-3">
          <div class="col-xl-6 col-md-10 col-sm-12">
            <div class="input-group">
              <span class="input-group-text col-3">{{
                "profile.name" | translate
              }}</span>
              <input
                type="text"
                class="form-control col-3"
                [disabled]="true"
                [value]="userService.name | async"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-xl-6 col-md-10 col-sm-12">
            <div class="input-group">
              <span class="input-group-text col-3">{{
                "profile.email" | translate
              }}</span>
              <input
                type="text"
                class="form-control"
                [disabled]="true"
                [value]="userService.email | async"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-xl-6 col-md-10 col-sm-12">
            <div class="input-group">
              <span class="input-group-text col-3">{{
                "profile.type" | translate
              }}</span>
              <input
                type="text"
                class="form-control"
                [disabled]="true"
                [value]="userService.type | async"
              />
            </div>
          </div>
        </div>
        <ng-container *ngIf="(userService.typeId | async) == 2">
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-3">{{
                  "profile.company_name" | translate
                }}</span>
                <input
                  type="text"
                  class="form-control"
                  [disabled]="true"
                  [value]="userService.companyName | async"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-3">{{
                  "profile.shortname" | translate
                }}</span>
                <input
                  type="text"
                  class="form-control"
                  [disabled]="true"
                  [value]="userService.shortName | async"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-3">{{
                  "profile.tax_number" | translate
                }}</span>
                <input
                  type="text"
                  class="form-control"
                  [disabled]="true"
                  [value]="userService.taxNumber | async"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row justify-content-center mt-3" *ngIf="customer">
          <div class="col-xl-6 col-md-10 col-sm-12 d-flex align-center">
            <span class="input-group-text col-auto">
              {{ "profile.message-language.text" | translate }}
            </span>
            <div class="btn-group mx-2">
              <button
                class="btn"
                [ngClass]="
                  customer.langid == 1 ? 'btn-primary' : 'btn-secondary'
                "
                (click)="changeCustomerLanguage(1)"
              >
                {{ "profile.message-language.hu" | translate }}
              </button>
              <button
                class="btn"
                [ngClass]="
                  customer.langid == 2 ? 'btn-primary' : 'btn-secondary'
                "
                (click)="changeCustomerLanguage(2)"
              >
                {{ "profile.message-language.en" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3" *ngIf="customer">
          <div class="col-xl-6 col-md-10 col-sm-12 d-flex align-center">
            <span class="input-group-text col-auto">
              {{ "profile.currency.text" | translate }}
            </span>
            <div class="btn-group mx-2">
              <button
                class="btn"
                [ngClass]="
                  customer.currencyid == currency.id
                    ? 'btn-primary'
                    : 'btn-secondary'
                "
                *ngFor="let currency of currencies"
                (click)="changeCustomerCurrency(currency.id)"
              >
                {{ currency.name }}
              </button>
            </div>
          </div>
        </div>
        <h2 class="text-center mt-5">
          {{ "profile.password.title" | translate }}
        </h2>
        <div [formGroup]="passwordForm">
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-auto">{{
                  "profile.password.current_password" | translate
                }}</span>
                <input
                  [type]="currentPasswordHide ? 'password' : 'text'"
                  class="form-control"
                  id="user-data-old-password"
                  required
                  name="password"
                  formControlName="current_password"
                />
                <button
                  (click)="toggleCurrentPassword($event)"
                  class="btn btn-outline-secondary"
                >
                  <fa-icon
                    [icon]="currentPasswordHide ? faEye : faEyeSlaysh"
                  ></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-auto">{{
                  "profile.password.new_password" | translate
                }}</span>
                <input
                  [type]="passwordHide ? 'password' : 'text'"
                  class="form-control"
                  id="user-data-password"
                  required
                  minlength="8"
                  name="password"
                  formControlName="password"
                  (change)="confirmpassword?.updateValueAndValidity()"
                />
                <button
                  (click)="togglePassword($event)"
                  class="btn btn-outline-secondary"
                >
                  <fa-icon
                    [icon]="passwordHide ? faEye : faEyeSlaysh"
                  ></fa-icon>
                </button>
              </div>
              <div
                class="alert alert-danger p-1 mt-1"
                *ngIf="password?.invalid && password?.touched"
              >
                <div *ngIf="password?.errors?.['required']">
                  {{ "register.password_error_required" | translate }}
                </div>
                <div *ngIf="!password?.value?.match('^(?=.*[A-Z])')">
                  {{ "register.password_error_require_uppercase" | translate }}
                </div>
                <div *ngIf="!password?.value?.match('(?=.*[a-z])')">
                  {{ "register.password_error_require_lowercase" | translate }}
                </div>
                <div *ngIf="!password?.value?.match('(.*[0-9].*)')">
                  {{ "register.password_error_require_number" | translate }}
                </div>
                <div *ngIf="password?.errors?.['minlength']">
                  {{ "register.password_error_min_length" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 col-md-10 col-sm-12">
              <div class="input-group">
                <span class="input-group-text col-auto">{{
                  "register.confirm_password" | translate
                }}</span>
                <input
                  [type]="password2Hide ? 'password' : 'text'"
                  class="form-control"
                  id="user-data-confirmpassword"
                  formControlName="confirmpassword"
                  (change)="confirmpassword?.updateValueAndValidity()"
                />
                <button
                  (click)="togglePassword2($event)"
                  class="btn btn-outline-secondary"
                >
                  <fa-icon
                    [icon]="password2Hide ? faEye : faEyeSlaysh"
                  ></fa-icon>
                </button>
              </div>

              <ng-container
                *ngIf="confirmpassword?.invalid && confirmpassword?.touched"
              >
                <div
                  class="alert alert-danger p-1 mt-1"
                  *ngIf="confirmpassword?.errors?.['match']"
                >
                  {{ "register.confirm_password_error_match" | translate }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <button
              class="btn btn-outline-danger col-auto"
              (click)="changePassword()"
              [disabled]="!passwordForm.valid"
            >
              {{ "profile.password.change_password" | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <button ngbNavLink>{{ "profile.nav.billing" | translate }}</button>
      <ng-template ngbNavContent>
        <app-billing-address
          (addressChanged)="apiCalls()"
        ></app-billing-address>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="3"
      *ngIf="customer && customer.addresses && customer.addresses.length > 0"
    >
      <button ngbNavLink>{{ "profile.nav.cards" | translate }}</button>
      <ng-template ngbNavContent> <app-cards></app-cards> </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
