import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-success-modal",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./success-modal.component.html",
  styleUrl: "./success-modal.component.scss",
})
export class SuccessModalComponent {}
