import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CustomerType } from "../../../../Core/models/Master";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { PasswordStrengthDirective } from "../../../../Core/Directives/passwordStrength-validator.directive";
import { passwordStrengthValidator } from "../../../../Core/Validators/passwordStrength.validator";
import { MatchDirective } from "../../../../Core/Directives/match-validator.directive";
import { matchValidator } from "../../../../Core/Validators/match.validator";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
@Component({
  selector: "CLM-user-data",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PasswordStrengthDirective,
    MatchDirective,
    TranslateModule,
  ],
  templateUrl: "./user-data.component.html",
  styleUrl: "./user-data.component.scss",
})
export class UserDataComponent implements OnInit {
  @Input() google: string = "";

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }
  emailRegx: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  passwordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  password_control: FormControl = new FormControl("", [
    Validators.required,
    passwordStrengthValidator(),
  ]);

  confirmpassword_control: FormControl = new FormControl("", [
    Validators.required,
    matchValidator(this.password_control),
  ]);

  public userForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.minLength(4)]),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern(this.emailRegx),
    ]),
    typeid: new FormControl("", [Validators.required]),
    cname: new FormControl(),
    taxnumber: new FormControl(),
    shortname: new FormControl(),
    position: new FormControl(),
    password: this.password_control,
    confirmpassword: this.confirmpassword_control,
  });

  faEye = faEye;
  faEyeSlaysh = faEyeSlash;
  passwordHide: Boolean = true;
  password2Hide: Boolean = true;
  CustomerTypes: CustomerType[] = [];
  onLangChange?: Subscription;
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {
    this.onLangChange = this.translateService.onLangChange.subscribe({
      next: () => {
        this.apiCall();
      },
    });
    this.apiCall();
  }
  ngOnInit(): void {
    if (this.google) {
      this.userForm.get("email")?.setValue(this.decodeToken(this.google).email);
      this.userForm.get("email")?.disable();
    }
  }

  apiCall() {
    this.apiService.getCustomerTypes().subscribe({
      next: (value) => {
        if (value.result && value.data) {
          this.CustomerTypes = value.data;
        }
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  togglePassword() {
    this.passwordHide = !this.passwordHide;
  }
  togglePassword2() {
    this.password2Hide = !this.password2Hide;
  }
  get name() {
    return this.userForm.get("name");
  }

  get email() {
    return this.userForm.get("email");
  }

  get password() {
    return this.userForm.get("password");
  }

  get confirmpassword() {
    return this.userForm.get("confirmpassword");
  }

  get typeid() {
    return this.userForm.get("typeid");
  }

  get cname() {
    return this.userForm.get("cname");
  }

  get shortname() {
    return this.userForm.get("shortname");
  }

  get position() {
    return this.userForm.get("position");
  }

  onSubmit() {
    console.log(this.userForm.valid);
  }
}
