<section>
  <h1 class="text-center pt-5">
    {{ "home-page.contact-section.title" | translate }}
  </h1>
  <div class="container container-flex pt-5">
    <div class="left-side">
      <div class="email-conatiner">
        <div class="logo"><i class="bi bi-envelope"></i></div>
        <h2 class="text-center">
          {{ "home-page.contact-section.email-section.title" | translate }}
        </h2>
        <a href="mailto:info@clmaster.eu">{{ "info@clmaster.eu" }}</a>
      </div>
      <div class="company-container">
        <div class="logo"><i class="bi bi-info-circle"></i></div>
        <h2 class="text-center">
          {{ "home-page.contact-section.company-section.title" | translate }}
        </h2>
        <span class="text-center">{{
          "home-page.contact-section.company-section.description" | translate
        }}</span>
      </div>
    </div>
    <div class="right-side">
      <div class="message-container">
        <div class="container">
          <form>
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  [(ngModel)]="name"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="{{ 'basic.name' | translate }}"
                />
              </div>
              <div class="col-6">
                <input
                  type="text"
                  [(ngModel)]="email"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="{{ 'basic.email' | translate }}"
                />
              </div>
              <div class="col-12">
                <input
                  type="text"
                  [(ngModel)]="subject"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="{{ 'basic.subject' | translate }}"
                />
              </div>
              <div class="col-12">
                <textarea
                  type="text"
                  [(ngModel)]="message"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="{{ 'basic.message' | translate }}"
                ></textarea>
              </div>
              <div class="col-12" class="text-center pt-3">
                <button
                  class="send-btn"
                  [disabled]="!name || !email || !subject || !message"
                  (click)="sendMessage()"
                >
                  {{ "basic.send-message" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
