import {
  AbstractControl,
  FormControl,
  NgModel,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function matchValidator(target: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value != target.value ? { match: true } : null;
  };
}
