import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-confirm",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./confirm.component.html",
  styleUrl: "./confirm.component.scss",
})
export class ConfirmComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) {}

  result: boolean = false;
  success: boolean = false;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.apiService.UserActivateLink(params["secret"]).subscribe({
        next: (data) => {
          this.result = true;
          if (data.result) {
            this.success = true;
          } else {
            this.success = false;
          }
        },
      });
    });
  }
}
