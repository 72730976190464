import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(private dialog: NgbModal) {}
  openConfirmDialog(
    title: string,
    message: string,
    confirmText: string,
    declineText: string
  ) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      size: 'l',
      centered: true,
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmText = confirmText;
    dialogRef.componentInstance.declineText = declineText;
    return dialogRef.closed;
  }
}
