import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-subscription-cancel",
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule],
  templateUrl: "./subscription-cancel.component.html",
  styleUrl: "./subscription-cancel.component.scss",
})
export class SubscriptionCancelComponent {
  constructor() {}
  activeModal = inject(NgbActiveModal);
  reasons = ["cost", "performance", "switch", "unncecessary", "support"];
  selectedReason = "";
  message = "";
  cancelSubscription() {
    this.activeModal.close({
      reason: this.selectedReason,
      message: this.message,
    });
  }
}
