<div class="container my-3">
  <h2 class="text-center">{{ title }}</h2>
  <p>{{ message }}</p>
  <div class="row mx-3 gap-2">
    <button class="btn btn-outline-primary col" (click)="confirm()">
      {{ confirmText }}
    </button>
    <button class="btn btn-outline-danger col" (click)="decline()">
      {{ declineText }}
    </button>
  </div>
</div>
