<div class="vps-card-container">
  <div class="vps-disabled" *ngIf="!vps.enabled">
    {{ "vps-page.vps-disabled" | translate }}
  </div>
  <div class="title">{{ vps.sname }}</div>
  <div class="price-panel">
    <div class="price" *ngIf="currency">
      <span class="price-value">{{
        currencyService.printCurrency(vps.prices[currency.code]?.price)
      }}</span>
    </div>
    <div class="price-info">
      + {{ "basic.price.vat" | translate }} / {{ vps.mename }}
    </div>
  </div>
  <div class="info-panel">
    <div *ngFor="let param of vps.params" class="info">
      <span>{{ param.name }}</span
      >: {{ param.value }}
      <i class="bi bi-info-circle" [ngbTooltip]="param.info"></i>
    </div>
  </div>
  <div class="btn-panel">
    <ng-container *ngIf="cartService.prodInCartCount(vps.id) >= vps.maxorder">
      <button class="config-btn" [disabled]="true">
        {{ "basic.cantadd" | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="cartService.prodInCartCount(vps.id) < vps.maxorder">
      <button
        class="config-btn"
        [routerLink]="[category, vps.sname]"
        [disabled]="!vps.enabled"
      >
        {{ "basic.configurate" | translate }}
      </button>
    </ng-container>
  </div>
</div>
