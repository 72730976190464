import { EventEmitter, Injectable, inject } from "@angular/core";
import { ApiService } from "../Api/api.service";
import { Observable, catchError, map, of, shareReplay, tap } from "rxjs";
import { Response } from "../../models/Response";
import { Router } from "@angular/router";
import { ConsoleService } from "../Console/console.service";
import { CurrencyService } from "../Currency/currency.service";
import { ClmCookieService } from "../Cookie/clm-cookie.service";
import { TranslateService } from "@ngx-translate/core";

export interface UserData {
  loggedIn: boolean;
  data?: {
    id: number;
    email: string;
    activated: boolean;
    name: string;
    langid: number;
  };
}

export interface CustomerData {
  code?: string;
  typeId?: number;
  type?: string;
  name?: string;
  shortname?: string;
  taxNumber?: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  sessionString: string = "apitoken";

  get isLoggedIn(): Observable<boolean> {
    return this.getData().pipe(map((x) => x.loggedIn));
  }

  get isActivated(): Observable<boolean> {
    return this.getData().pipe(map((x) => (x.data ? x.data.activated : false)));
  }

  get email(): Observable<string> {
    return this.getData().pipe(map((x) => (x.data ? x.data.email : "")));
  }

  get name(): Observable<string> {
    return this.getData().pipe(map((x) => (x.data ? x.data.name : "")));
  }

  get langid(): Observable<number> {
    return this.getData().pipe(map((x) => (x.data ? x.data.langid : 1)));
  }

  get activated(): Observable<boolean> {
    return this.getData().pipe(map((x) => (x.data ? x.data.activated : true)));
  }

  get type(): Observable<string> {
    return this.getCustomerData().pipe(map((x) => (x.type ? x.type : "")));
  }

  get typeId(): Observable<number> {
    return this.getCustomerData().pipe(map((x) => (x.typeId ? x.typeId : "")));
  }

  get companyName(): Observable<string> {
    return this.getCustomerData().pipe(map((x) => (x.name ? x.name : "")));
  }

  get shortName(): Observable<string> {
    return this.getCustomerData().pipe(
      map((x) => (x.shortName ? x.shortName : ""))
    );
  }

  get taxNumber(): Observable<string> {
    return this.getCustomerData().pipe(
      map((x) => (x.taxNumber ? x.taxNumber : ""))
    );
  }

  get id(): Observable<number | undefined> {
    return this.getData().pipe(map((x) => (x.data ? x.data.id : undefined)));
  }

  public customerId?: number;

  private data?: Observable<{ loggedIn: boolean; data?: any }>;

  private customerData?: Observable<any>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private consoleService: ConsoleService,
    private currencyService: CurrencyService,
    private cookieService: ClmCookieService,
    private translateService: TranslateService
  ) {
    this.getUserData();
  }

  responseToUserData(x: Response<any>): UserData {
    return x.result
      ? {
          loggedIn: true,
          data: {
            id: x.data.id,
            email: x.data.email,
            activated: x.data.activated,
            name: x.data.name,
            langid: x.data.langid,
          },
        }
      : { loggedIn: false };
  }

  responseToCustomerData(x: Response<any>): CustomerData {
    return x.result
      ? {
          code: x.data[0].code,
          type: x.data[0].typename,
          typeId: x.data[0].typeid,
          name: x.data[0].name,
          shortname: x.data[0].shortname,
          taxNumber: x.data[0].taxnumber,
        }
      : {};
  }

  getData(): Observable<UserData> {
    if (!this.data) {
      this.data = this.apiService.GetUser().pipe(
        catchError((err) =>
          of({
            loggedIn: false,
            result: false,
            data: null,
            error: "",
            servertime: "",
          })
        ),
        map(this.responseToUserData),
        shareReplay(1)
      );
    }
    return this.data;
  }

  refreshData(): Observable<{ loggedIn: boolean; data?: any }> {
    let temp = this.apiService.GetUser().pipe(
      catchError((err) =>
        of({
          loggedIn: false,
          result: false,
          data: null,
          error: "",
          servertime: "",
        })
      ),
      map(this.responseToUserData),
      shareReplay(1)
    );
    this.data = temp;
    return this.data;
  }

  public isUserLoggedIn(): Observable<boolean> {
    return this.getData().pipe(
      catchError((err) =>
        of({
          loggedIn: false,
          result: false,
          data: null,
          error: "",
          servertime: "",
        })
      ),
      map((x) => x.loggedIn)
    );
  }

  public isUserActivated(): Observable<boolean> {
    return this.getData().pipe(
      catchError((err) =>
        of({
          loggedIn: false,
          result: false,
          data: null,
          error: "",
          servertime: "",
        })
      ),
      map((x) =>
        x.loggedIn ? (x.data?.activated ? x.data.activated : false) : true
      )
    );
  }

  getCustomerData(): Observable<any> {
    if (!this.customerData) {
      this.customerData = this.apiService
        .GetCustomer()
        .pipe(map(this.responseToCustomerData), shareReplay(1));
    }
    return this.customerData;
  }

  refreshCustomerData(): Observable<any> {
    this.customerData = this.apiService
      .GetCustomer()
      .pipe(map(this.responseToCustomerData), shareReplay(1));
    return this.customerData;
  }

  public getUserData() {
    this.refreshData().subscribe((val) => {});
    this.apiService.GetCustomer().subscribe({
      next: (value) => {
        if (!value.error) this.customerId = value.data[0].id;
      },
    });
  }

  login(email: string, password: string) {
    return this.apiService.UserLogin(email, password);
  }
  getUser() {}

  public userLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  loggedIn() {
    this.userLoggedIn.emit(true);
  }

  logout() {
    this.data?.subscribe((x) => {
      if (x.loggedIn) {
        localStorage.removeItem(this.sessionString);
        this.data = of({ loggedIn: false });
        this.consoleService.clearConsoles();
        let currency = this.currencyService.getCurrentCurrency();
        if (currency) {
          this.apiService.changeCustomerCurrency(currency.id).subscribe((x) => {
            if (x.result) {
              localStorage.setItem("apitoken", x.data);
            }
            const langId = this.cookieService.check("language")
              ? this.cookieService.get("language") == "hu"
                ? 1
                : 2
              : 1;
            this.translateService.use(langId == 1 ? "hu" : "en");
            this.apiService.changeCustomerLanguage(langId).subscribe((x) => {
              if (x.result) {
                localStorage.setItem("apitoken", x.data);
              }
              this.router.navigate(["/"]);
            });
          });
        }
      }
    });
  }
}
