<div class="offcanvas-header">
  <h4 class="offcanvas-title" id="offcanvas-basic-title"></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeOffcanvas.dismiss('Cross click')"
  ></button>
</div>
<div class="offcanvas-body">
  <div class="d-flex flex-column text-center h-100 justify-content-center">
    <form class="align-self-center w-100">
      <div class="form-floating mb-3">
        <input
          type="email"
          name="email"
          class="form-control"
          id="floatingInput"
          placeholder="name@example.com"
          [(ngModel)]="userEmail"
        />
        <label for="floatingInput">{{ "login.email" | translate }}</label>
      </div>
      <div class="form-floating">
        <input
          type="password"
          name="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Password"
          [(ngModel)]="userPassword"
        />
        <label for="floatingPassword">{{ "login.password" | translate }}</label>
      </div>
      <div class="d-grid gap-2 mt-3">
        <button type="button" class="btn btn-outline-success" (click)="login()">
          {{ "login.login" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-outline-danger"
          [routerLink]="['/register']"
          (click)="activeOffcanvas.dismiss('register')"
        >
          {{ "login.register" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-outline-warning"
          [routerLink]="['/forgotPassword']"
          (click)="activeOffcanvas.dismiss('forgotPassword')"
        >
          {{ "login.forgot" | translate }}
        </button>
        <div id="google-signin-button"></div>
      </div>
    </form>
  </div>
</div>
