import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ScrollService {
  constructor() {}
  scrollTo(id: string, behavior = "smooth") {
    const header: HTMLElement | null = document.getElementById("header");
    const offset = header?.getBoundingClientRect().height;

    const element = document.getElementById(id);

    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top;
    if (!element || !offset || bodyRect == null || !elementRect) {
      return;
    }

    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: behavior as ScrollBehavior,
    });
  }
}
