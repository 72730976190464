<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <h1 class="text-center m-2 p-2 fs-1 fw-bold">
    {{ "register.title" | translate }}
  </h1>
  <div class="row mt-3">
    <div class="col-6 mt-3">
      <label for="user-data-name" class="form-label">{{
        "register.name" | translate
      }}</label>
      <input
        id="user-data-name"
        class="form-control"
        type="text"
        formControlName="name"
      />
      <ng-container *ngIf="name?.invalid && name?.touched">
        <div
          class="alert alert-danger p-1 mt-1"
          *ngIf="name?.errors?.['required']"
        >
          {{ "register.name_error_required" | translate }}
        </div>
        <div
          class="alert alert-danger p-1 mt-1"
          *ngIf="name?.errors?.['minlength']"
        >
          {{ "register.name_error_min_length" | translate }}
        </div>
      </ng-container>
    </div>
    <div class="col-6 mt-3">
      <label for="user-data-email" class="form-label">{{
        "register.email" | translate
      }}</label>
      <input
        id="user-data-email"
        class="form-control"
        type="text"
        formControlName="email"
        [disabled]="google != ''"
      />
      <ng-container *ngIf="email?.invalid && email?.touched">
        <div
          class="alert alert-danger p-1 mt-1"
          *ngIf="email?.errors?.['required']"
        >
          {{ "register.email_error_required" | translate }}
        </div>
        <div
          class="alert alert-danger p-1 mt-1"
          *ngIf="email?.errors?.['pattern']"
        >
          {{ "register.email_error_invalid" | translate }}
        </div>
      </ng-container>
    </div>
    <div class="col-6 mt-3">
      <label for="user-data-type" class="form-label">{{
        "register.customer_type" | translate
      }}</label>
      <select class="form-select" id="user-data_type" formControlName="typeid">
        <option
          *ngFor="let customerType of CustomerTypes"
          value="{{ customerType.id }}"
        >
          {{ customerType.name }}
        </option>
      </select>
    </div>
    <div class="w-100"></div>
    <div class="col-6 mt-3" *ngIf="typeid?.value == '2'">
      <label for="user-data-cname" class="form-label">{{
        "register.company_name" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="user-data-cname"
        placeholder=""
        formControlName="cname"
      />
    </div>
    <div class="col-6 mt-3" *ngIf="typeid?.value == '2'">
      <label for="user-data-shortname" class="form-label">{{
        "register.company_short_name" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="user-data-shortname"
        placeholder=""
        formControlName="shortname"
      />
    </div>
    <div class="col-6 mt-3" *ngIf="typeid?.value == '2'">
      <label for="user-data-taxnumber" class="form-label">{{
        "register.company_tax" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="user-data-taxnumber"
        placeholder=""
        formControlName="taxnumber"
      />
    </div>
    <div class="col-6 mt-3" *ngIf="typeid?.value == '2'">
      <label for="user-data-position" class="form-label">{{
        "register.company_position" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="user-data-position"
        placeholder=""
        formControlName="position"
      />
    </div>
    <div class="col-6 mt-3">
      <label for="user-data-password" class="form-label">{{
        "register.password" | translate
      }}</label>
      <div class="input-group">
        <input
          [type]="passwordHide ? 'password' : 'text'"
          class="form-control"
          id="user-data-password"
          placeholder="{{ 'register.password_placeholder' | translate }}"
          required
          minlength="8"
          name="password"
          formControlName="password"
          (change)="confirmpassword?.updateValueAndValidity()"
        />
        <button (click)="togglePassword()" class="btn btn-outline-secondary">
          <fa-icon [icon]="passwordHide ? faEye : faEyeSlaysh"></fa-icon>
        </button>
      </div>
      <div
        class="alert alert-danger p-1 mt-1"
        *ngIf="password?.invalid && password?.touched"
      >
        <div *ngIf="password?.errors?.['required']">
          {{ "register.password_error_required" | translate }}
        </div>
        <div *ngIf="!password?.value?.match('^(?=.*[A-Z])')">
          {{ "register.password_error_require_uppercase" | translate }}
        </div>
        <div *ngIf="!password?.value?.match('(?=.*[a-z])')">
          {{ "register.password_error_require_lowercase" | translate }}
        </div>
        <div *ngIf="!password?.value?.match('(.*[0-9].*)')">
          {{ "register.password_error_require_number" | translate }}
        </div>
        <div *ngIf="password?.errors?.['minlength']">
          {{ "register.password_error_min_length" | translate }}
        </div>
      </div>
    </div>
    <div class="col-6 mt-3">
      <label for="user-data-confirmpassword" class="form-label">{{
        "register.confirm_password" | translate
      }}</label>
      <div class="input-group">
        <input
          [type]="password2Hide ? 'password' : 'text'"
          class="form-control"
          id="user-data-confirmpassword"
          placeholder="{{ 'register.password_placeholder' | translate }}"
          formControlName="confirmpassword"
          (change)="confirmpassword?.updateValueAndValidity()"
        />
        <button (click)="togglePassword2()" class="btn btn-outline-secondary">
          <fa-icon [icon]="password2Hide ? faEye : faEyeSlaysh"></fa-icon>
        </button>
      </div>

      <ng-container
        *ngIf="confirmpassword?.invalid && confirmpassword?.touched"
      >
        <div
          class="alert alert-danger p-1 mt-1"
          *ngIf="confirmpassword?.errors?.['match']"
        >
          {{ "register.confirm_password_error_match" | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</form>
