import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { UserService } from "../../../Core/Services/User/user.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PasswordStrengthDirective } from "../../../Core/Directives/passwordStrength-validator.directive";
import { MatchDirective } from "../../../Core/Directives/match-validator.directive";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { passwordStrengthValidator } from "../../../Core/Validators/passwordStrength.validator";
import { matchValidator } from "../../../Core/Validators/match.validator";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastService } from "../../../Core/Toast/toast.service";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { BillingAddressComponent } from "./billing-address/billing-address.component";
import { CardsComponent } from "./cards/cards.component";
import { Subscription } from "rxjs";
import { CurrencyService } from "../../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-profile",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthDirective,
    MatchDirective,
    NgbNavModule,
    BillingAddressComponent,
    CardsComponent,
  ],
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent implements OnInit, OnDestroy {
  active = 1;
  constructor(
    private apiService: ApiService,
    public userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private currencyService: CurrencyService
  ) {}

  customer: any;
  onLangChange?: Subscription;
  onCurrencyChange?: Subscription;
  currencies: any[] = [];

  ngOnInit(): void {
    this.apiService.getCurrencies().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        this.currencies = response.data;
      },
    });
    this.onCurrencyChange = this.currencyService.onChange.subscribe(() => {
      this.apiCalls();
    });
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.apiCalls();
    });

    this.apiCalls();
  }

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
  }

  apiCalls() {
    this.userService.refreshCustomerData().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
      },
    });

    this.apiService.GetCustomer().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        this.customer = response.data[0];
        this.apiService.GetCustomerAddress().subscribe({
          next: (response) => {
            if (response.error) {
              this.userService.logout();
            }
            this.customer.addresses = response.data;
          },
        });
      },
    });
  }

  changeCustomerLanguage(id: number) {
    this.apiService.changeCustomerLanguage(id, this.customer.id).subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        this.apiCalls();
      },
    });
  }

  changeCustomerCurrency(id: number) {
    const userId = this.userService.id.subscribe((userid) => {
      this.apiService
        .changeCustomerCurrency(id, this.customer.id, userid)
        .subscribe({
          next: (response) => {
            if (response.error) {
              this.userService.logout();
              return;
            }
            localStorage.setItem("apitoken", response.data);
            this.currencyService.setCurrentCurrency(id);
            this.apiCalls();
          },
        });
    });
  }

  passwordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  password_control: FormControl = new FormControl("", [
    Validators.required,
    passwordStrengthValidator(),
  ]);

  confirmpassword_control: FormControl = new FormControl("", [
    Validators.required,
    matchValidator(this.password_control),
  ]);

  public passwordForm = new FormGroup({
    current_password: new FormControl("", [Validators.required]),
    password: this.password_control,
    confirmpassword: this.confirmpassword_control,
  });

  faEye = faEye;
  faEyeSlaysh = faEyeSlash;
  passwordHide: Boolean = true;
  password2Hide: Boolean = true;
  currentPasswordHide: Boolean = true;
  togglePassword(e: Event) {
    e.stopPropagation();
    this.passwordHide = !this.passwordHide;
  }
  togglePassword2(e: Event) {
    e.stopPropagation();
    this.password2Hide = !this.password2Hide;
  }

  toggleCurrentPassword(e: Event) {
    e.preventDefault();
    this.currentPasswordHide = !this.currentPasswordHide;
  }

  get password() {
    return this.passwordForm.get("password");
  }

  get confirmpassword() {
    return this.passwordForm.get("confirmpassword");
  }

  changePassword() {
    let oldpassword = this.passwordForm.get("current_password")?.value;
    let newPassword = this.passwordForm.get("password")?.value;
    if (oldpassword && newPassword) {
      this.apiService.changeUserPassword(oldpassword, newPassword).subscribe({
        next: (response) => {
          this.toastService.showAlert(response.error);
          if (response.result) {
            this.passwordForm.reset();
          }
        },
      });
    }
  }
}
