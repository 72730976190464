import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox-input',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './checkbox-input.component.html',
  styleUrl: './checkbox-input.component.css',
})
export class CheckboxInputComponent {
  @Input() name = '';
  @Input() label = '';
  @Input() extra: any;
  @Input() cartName: string = 'cartname';
  @Output() onChange: EventEmitter<never> = new EventEmitter<never>();
}
